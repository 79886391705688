<template>
  <header v-if="content">
    <HomeButton />
    <div class="banner">
      <h1 class="banner__title" v-html="content.title"></h1>
      <p class="banner__subtitle" v-html="content.subtitle"></p>
      <img :src="content.image" alt="" class="banner__image"/>
    </div>
  </header>
</template>

<script>
import { HomeButton } from '@/components/buttons'

export default {
  name: 'Banner',
  components: {
    HomeButton
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  }
}
</script>
