<template>
  <Transition name="fade">
    <template v-if="$page">
      <div class="content">
        <div class="home">
          <img src="@/assets/images/logo.svg" alt="" class="home__logo">
          <h1 class="home__title">{{ $page.title }}</h1>
          <p class="home__subtitle">{{ $page.subtitle }}</p>
          <div class="navigation__group">
            <PageButton v-for="index in [0, 1, 2]" :key="index" :step="index"/>
          </div>
          <img class="img--background" :src="$page.background" alt="" srcset="">
        </div>
      </div>
    </template>
  </Transition>
  <Transition name="fade">
    <template v-if="!$page">
      <Loader/>
    </template>
  </Transition>
</template>

<script>
import {setMeta} from "@/helpers/metaHelpers";
import {PageButton} from "@/components/buttons";
import { Loader } from '@/components'

export default {
  components: {PageButton, Loader},
  name: 'Home',
  beetPage: 'terminal-home',
  data() {
    return {
      open: true
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  }
}
</script>
