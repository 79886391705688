import {createRouter, createWebHistory} from 'vue-router'
import {About, ContentLibrary, Event, Home } from '@/views'
import { generateRoutes } from '@boite-beet/lang-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/event',
        name: 'events',
        component: Event
    },
    {
        path: '/content-library',
        name: 'content-library',
        component: ContentLibrary
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: Home
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes,
    scrollBehavior () {    
        return {
          top: 0
        }
      }
})

export default router
