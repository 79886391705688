<template>
  <Transition name="fade">
    <template v-if="$beet.isReady">
      <router-view/>
    </template>
  </Transition>
  <Transition name="fade">
    <template v-if="!$beet.isReady">
      <Loader/>
    </template>
  </Transition>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: 'App',
  components: {Loader},
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  }
}
</script>
