<template>
  <section v-if="content" class="content-image">
    <img class="content-image__image" :src="content.image" alt=""/>
    <TitleGroup :content="titleSection" class="content-image__content"></TitleGroup>
  </section>
</template>

<script>
import TitleGroup from "@/components/TitleGroup";
export default {
  name: 'ContentImageBlock',
  components: {TitleGroup},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        label: this.content.label,
        title: this.content.title,
        text: this.content.text
      }
    }
  }
}
</script>
