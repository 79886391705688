<template>
  <footer class="footer" v-if="currentStep || currentStep === 0">
    <p class="subtitle">Consultez les autres sections</p>
    <div class="navigation__group --footer">
      <PageButton v-for="(step, stepkey) in steps" :key="stepkey" :step="step"/>
    </div>
  </footer>
</template>

<script>
import {PageButton} from "@/components/buttons";
export default {
  name: 'Footer',
  components: {
    PageButton
  },
  computed: {
    steps() {
      return [0, 1, 2].filter(step => step !== this.currentStep)
    }
  },
  props: {
    currentStep: {
      type: Number,
      default: null
    }
  }
}
</script>
