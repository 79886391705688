import { createStore } from 'vuex'

export default createStore({
  state: {
    pages: {
      0: {
        icon: 'flag',
        name: 'À Propos du centre',
        routerName: 'about'
      },
      1: {
        icon: 'calender',
        name: 'Événements à venir',
        routerName: 'events'
      },
      2: {
        icon: 'contentLibrary',
        name: 'Bibliothèque de contenus',
        routerName: 'content-library'
      }
    }
  },
  getters: {
    getStep(state) {
      return state.pages
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
