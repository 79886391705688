<template>
  <time class="date" v-if="date">
    <Icon id="dateFill" class="date__icon"/>
    <span>{{formatDate(date)}}</span>
  </time>
</template>

<script>
import {Icon} from "@/components/global";
export default {
  name: "Date",
  components: {
    Icon
  },
  props: {
    date: {
      type: String,
      default: null
    }
  },
  methods: {
    formatDate(date) {
      return (new Date(date.slice(0, 19))).toLocaleString(this.$i18n.locale + '-CA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'America/Montreal',
      })
    }
  }
}
</script>

