<template>
  <div v-if="content">
    <h2 class="title">{{content.label}}</h2>
    <h3 class="subtitle">{{content.title}}</h3>
    <p class="text" v-if="content.text">{{content.text}}</p>
  </div>
</template>

<script>
export default {
  name: "TitleGroup",
  props: {
    content: {
      type: Object,
      default: null
    }
  }
}
</script>
