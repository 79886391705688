<template>
  <section v-if="content" class="content-poles">
    <div class="content-poles__content">
      <TitleGroup :content="titleSection"/>
      <div class="video__preview --poles" @click="isModalOpen = !isModalOpen">
        <Icon id="play" class="video__preview-icon" />
        <img class="video__preview-image" :src="content.image" alt=""/>
        <Modal :is-open="isModalOpen">
          <YoutubeVideo :video-id="content.videoId" :is-open="isModalOpen"/>
        </Modal>
      </div>
    </div>
    <ul class="content-poles__list">
      <li v-for="(pole, poleKey) in content.poles" :key="poleKey" class="content-poles__list-item">
        <img :src="pole.icon" alt="" class="content-poles__list-icon">
        <h4 class="content-poles__list-title">{{pole.title}}</h4>
      </li>
    </ul>
  </section>
</template>

<script>
import Modal from '@/components/Modal.vue';
import {Icon} from '@/components/global';
import TitleGroup from "@/components/TitleGroup";
import YoutubeVideo from "@/components/YoutubeVideo";

export default {
  name: "PolesBlock",
  components: {
    YoutubeVideo,
    TitleGroup,
    Icon,
    Modal
  },
  data() {
    return {
      isModalOpen: false
    }
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        label: this.content.label,
        title: this.content.title,
        text: this.content.text
      }
    }
  }
}
</script>
