<template>
  <Transition name="fade">
    <div v-show="isModalOpen" class="modal">
      <button class="button --modal">
        <Icon id="close" />
        <span>{{buttonLabel}}</span>
      </button>
      <slot></slot>
      <div class="modal__background"></div>
    </div>
  </Transition>
</template>

<script>
import {Icon} from '@/components/global'

export default {
  name: 'Modal',
  components: {
    Icon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonLabel() {
      return this.$i18n.locale === 'fr' ? 'Fermer' : 'Close'
    },
    isModalOpen() {
      return this.isOpen
    }
  },
  watch: {
    isModalOpen() {
      this.isModalOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    }
  }
}
</script>
