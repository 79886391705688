<template>
  <div class="video__linkPrevent"></div>
  <div class="video__recommendedPrevent"></div>
  <iframe class="video__iframe" :src="videoLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>
export default {
  name: 'YoutubeVideo',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    videoId: {
      type: String,
      required: true
    }
  },
  computed: {
    videoLink() {
      return this.isOpen ? `https://www.youtube.com/embed/${this.videoId}?autoplay=1&loop=1&playlist=${this.videoId}&cc_load_policy=1&iv_load_policy=3&modestbranding=1&playsinline=1&rel=0&fs=0` : null
    }
  }
}
</script>
