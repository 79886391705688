<template>
  <section v-if="content" class="content-stats">
    <TitleGroup :content="titleSection" class="content-stats__content"/>
    <ul class="content-stats__list">
      <li class="content-stats__list-item" v-for="item in content.stats" :key="item">
        <Icon :id="item.icon" class="content-stats__list-icon"/>
        <p class="content-stats__list-title">{{ item.number }}</p>
        <p class="content-stats__list-subtitle">{{ item.title }}</p>
        <div class="content-stats__list-text">{{ item.text }}</div>
      </li>
    </ul>
  </section>
</template>

<script>
import {Icon} from '@/components/global';
import TitleGroup from "@/components/TitleGroup";

export default {
  name: 'StatsBlock',
  components: {
    TitleGroup,
    Icon
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        label: this.content.label,
        title: this.content.title,
        text: this.content.text
      }
    }
  }
}
</script>
