<template>
  <section v-if="content" class="content-video">
    <TitleGroup :content="titleSection" class="content-video__content"/>
    <div class="video__preview content-video__video" @click="isModalOpen = !isModalOpen">
      <Icon id="play" class="video__preview-icon" />
      <img class="video__preview-image" :src="content.image" alt=""/>
      <Modal :is-open="isModalOpen">
        <YoutubeVideo :is-open="isModalOpen" :video-id="content.videoId" />
      </Modal>
    </div>
  </section>
</template>

<script>
import {Icon} from '@/components/global';
import Modal from '@/components/Modal.vue';
import TitleGroup from "@/components/TitleGroup";
import YoutubeVideo from "@/components/YoutubeVideo";
export default {
  name: 'VideoBlock',
  components: {
    YoutubeVideo,
    TitleGroup,
    Icon,
    Modal
  },
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    titleSection() {
      return {
        label: this.content.label,
        title: this.content.title,
        text: this.content.text
      }
    }
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  }
}
</script>
