<template>
  <LangRouterLink :to="{ name: `${content.routerName}` }" class="navigation" v-if="step || step === 0">
    <Icon :id="content.icon" class="navigation__icon"/>
    <p class="navigation__name">{{ content.name }}</p>
    <Icon id="arrow" class="navigation__arrow" />
  </LangRouterLink>
</template>

<script>
import {Icon, LangRouterLink} from "@/components/global";

export default {
  name: 'PageButton',
  components: {
    Icon,
    LangRouterLink
  },
  props: {
    step: {
      type: Number,
      default: null,
    }
  },
  computed: {
    content() {
      return {
        icon: this.$store.getters.getStep[this.step].icon,
        name: this.$store.getters.getStep[this.step].name,
        routerName: this.$store.getters.getStep[this.step].routerName
      }
    }
  }
}
</script>
