<template>
  <div v-if="content" class="card" @click="isModalOpen = !isModalOpen">
    <div class="card__image">
      <img :src="content.image" alt="">
      <Date :date="content.date" class="--card" v-if="type === 'event'"/>
      <Icon id="play" class="video__preview-icon" v-if="type === 'video'"/>
    </div>
    <h2 class="card__title">{{ content.title }}</h2>
    <p class="card__description">{{ content.shortText }}</p>
    <p class="card__details">
      <span>{{ detailsLabel[type] }}</span>
      <Icon id="arrow" class="card__details-icon" v-if="type === 'event' || type === 'document'"/>
      <Icon id="playSmall" class="card__details-icon --play" v-if="type === 'video'"/>
    </p>
    <Modal :is-open="isModalOpen">
      <div v-if="type === 'document' && content.pages && parseInt(content.pages) > 1" class="modal__pdf-controls">
        <button @click.stop="changePage('minus')" class="modal__pdf-button --reverse">
          <Icon id="arrow" />
        </button>
        <p class="modal__pdf-count" @click.stop>{{pdfPage}}</p>
        <button @click.stop="changePage('plus')" class="modal__pdf-button">
          <Icon id="arrow" />
        </button>
      </div>
      <pdf v-if="type === 'document'" :src="content.file" :resize="true" class="modal__pdf" @click.stop="pdfPage++" :page="pdfPage" @numpages="maxPdfPage" ref="component"></pdf>
      <article v-if="type === 'event'" class="modal__article" @click.stop>
        <header class="modal__header">
          <h2 class="modal__title">{{ content.title }}</h2>
          <div class="modal__infos">
            <Date :date="content.date" class="--modal"/>
            <p class="date --modal">
              <Icon id="location"/>
              <span>{{ content.location }}</span>
            </p>
          </div>
          <img :src="content.image" alt="" class="modal__image">
        </header>
        <RawHtml tag="main" :html="content.content" class="modal__content"/>
      </article>
      <YoutubeVideo v-if="type === 'video'" :video-id="content.videoId" :is-open="isModalOpen" />
    </Modal>
  </div>
</template>

<script>
import {Icon, RawHtml} from "@/components/global";
import Date from '@/components/Date.vue';
import Modal from '@/components/Modal.vue';
import pdf from 'pdfvuer'
import YoutubeVideo from '@/components/YoutubeVideo.vue';

export default {
  name: 'ContentCard',
  components: {Date, Modal, Icon, pdf, RawHtml,YoutubeVideo},
  data() {
    return {
      isModalOpen: false,
      pdfPage: 1,
      maxPdfPage: parseInt(this.content.pages)
    }
  },
  props: {
    content: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    detailsLabel() {
      return this.$i18n.locale === 'fr' ? {
        'document': 'Consulter le document',
        'event': 'Plus de détails',
        'video': 'Visionner la vidéo'
      } : {
        'document': 'View the document',
        'event': 'See More',
        'video': 'Watch the video'
      }
    }
  },
  methods: {
    changePage(direction) {
      if(direction === 'plus') {
        this.pdfPage === this.maxPdfPage ? this.pdfPage = 1 : this.pdfPage++
      } else {
        this.pdfPage === 1 ? this.pdfPage = this.maxPdfPage : this.pdfPage--
      }
    }
  },
  watch: {
    isModalOpen() {
      if(!this.isModalOpen) {
        this.pdfPage = 1
      }
    }
  }
}
</script>
