import './startup'

import { createApp } from 'vue'
import mitt from 'mitt';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'
import langRouter from '@boite-beet/lang-router'
import scroll from '@/extensions/scroll'

import '@/styles/app.scss'

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(langRouter, 'fr')
    .use(GlobalComponents)
    .use(BeetAPI, {store, apiUrl: process.env.VUE_APP_API_URL})
    .use(scroll, {
        class: 'v-scroll-reveal',
        duration: 800,
        scale: 1,
        distance: '10px',
        mobile: false
    })

const emitter = mitt();
app.provide('emitter', emitter)

/* --- GOOGLE ANALYTICS ---
    import VueGtag from "vue-gtag";
    app.use(VueGtag, {
        config: { id: "GA_MEASUREMENT_ID" },
    }, router);
*/

app.mount('#app')
