<template>
  <LangRouterLink :to="{ name: 'home' }" class="button --back">
    <Icon id="arrow" class="button__icon"/>
    <span>{{ buttonLabel }}</span>
  </LangRouterLink>
</template>
<script>
import {Icon, LangRouterLink} from "@/components/global";

export default {
  name: 'HomeButton',
  components: {
    Icon,
    LangRouterLink
  },
  computed: {
    buttonLabel() {
      return this.$i18n.locale === 'fr' ? 'Retour à l\'accueil' : 'Back to home'
    }
  }
}
</script> 