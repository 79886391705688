<template>
  <Transition name="fade">
    <template v-if="$page">
      <div class="content">
        <Banner :content="bannerSection" />
        <main class="card__list" v-if="documents.length">
          <ContentCard v-for="(document, documentKey) in documents" :key="documentKey" :content="document" :type="document.type"/>
        </main>
        <Footer :current-step="2" />
      </div>
    </template>
  </Transition>
  <Transition name="fade">
    <template v-if="!$page">
      <Loader />
    </template>
  </Transition>
</template>

<script>
import { setMeta } from "@/helpers/metaHelpers";
import { Footer, Loader } from '@/components'
import Banner from "@/components/blocks/Banner";
import ContentCard from "@/components/ContentCard";

export default {
  name: "ContentLibrary",
  beetPage: "terminal-library",
  components: {
    ContentCard,
    Banner,
    Footer,
    Loader
  },
  watch: {
    $page(to) {
      setMeta(to.seo);
    },
  },
  computed: {
    bannerSection() {
      return {
        image: this.$page.header.image,
        subtitle: this.$page.header.subtitle,
        title: this.$page.header.title
      }
    },
    documents() {
      return this.$page.documents.map(document => {
        return {
          file: document.file && document.file !== '' ? document.file : null,
          image: document.image,
          pages: document.pages ? document.pages : null,
          shortText: document.text,
          title: document.title,
          type: document.videoId && document.videoId !== '' ? 'video' : 'document',
          videoId: document.videoId && document.videoId !== '' ? document.videoId : null
        }
      })
    }
  }
};
</script>
