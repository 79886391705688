<template>
  <Transition name="fade">
    <template v-if="$page">
      <div class="content">
        <Banner :content="bannerSection" />
        <ContentImageBlock :content="contentImageBlock" />
        <ADNBlock :content="adnBlock"/>
        <VideoBlock :content="videoBlock"/>
        <StatsBlock :content="statsBlock"/>
        <PolesBlock :content="polesBlock"/>
        <Footer :current-step="0" />
      </div>
    </template>
  </Transition>
  <Transition name="fade">
    <template v-if="!$page">
      <Loader />
    </template>
  </Transition>
</template>

<script>
import { setMeta } from "@/helpers/metaHelpers";
import { ADNBlock, Banner, ContentImageBlock, Footer, PolesBlock, StatsBlock, VideoBlock } from "@/components";
import Loader from "@/components/Loader";

export default {
  name: "About",
  beetPage: "terminal-about",
  components:{ ADNBlock, Banner, ContentImageBlock, Footer, Loader, PolesBlock, StatsBlock, VideoBlock },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    adnBlock() {
      return {
        items: this.$page.adn.items,
        label: this.$page.adn.label,
        title: this.$page.adn.title
      }
    },
    bannerSection() {
      return {
        image: this.$page.header.image,
        subtitle: this.$page.header.subtitle,
        title: this.$page.header.title
      }
    },
    contentImageBlock() {
      return {
        image: this.$page.mission.image,
        label: this.$page.mission.label,
        text: this.$page.mission.text,
        title: this.$page.mission.title
      }
    },
    polesBlock() {
      return {
        image: this.$page.poles.image,
        poles: Object.values(this.$beet['research-poles']),
        text: this.$page.poles.text,
        label: this.$page.poles.label,
        title: this.$page.poles.title,
        videoId: this.$page.poles.videoId,
      }
    },
    statsBlock() {
      return {
        label: this.$page.listen.label,
        stats: this.$page.listen.stats,
        text: this.$page.listen.text,
        title: this.$page.listen.title
      }
    },
    videoBlock() {
      return {
        image: this.$page.vision.image,
        label: this.$page.vision.label,
        text: this.$page.vision.text,
        title: this.$page.vision.title,
        videoId: this.$page.vision.videoId
      }
    }
  },
  watch: {
    $page(to) {
      setMeta(to.seo);
    }
  }
}
</script>
