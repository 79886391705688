<template>
  <section v-if="content" class="content-adn">
    <TitleGroup :content="titleSection"></TitleGroup>
    <ul class="content-adn__list">
      <li v-for="item in content.items" :key="item" class="content-adn__list-item">
        <img :src="item.image" alt="" class="content-adn__list-image"/>
        <h3 class="content-adn__list-title">{{ item.title }}</h3>
        <p>{{ item.text }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
import TitleGroup from "@/components/TitleGroup";

export default {
  name: 'ADNBlock',
  components: {TitleGroup},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        label: this.content.label,
        title: this.content.title,
        text: this.content.text
      }
    }
  }
}
</script>
