<template>
  <Transition name="fade">
    <template v-if="$page">
      <div class="content">
        <Banner :content="bannerSection"/>
        <main class="card__list" v-if="events.length">
          <ContentCard v-for="(event, eventKey) in events" :key="eventKey" :content="event" type="event"/>
        </main>
        <section v-else class="event__empty">
          <p>{{ noEvents }}</p>
          <LangRouterLink :to="{ name:'content-library' }" class="button --center">{{ libraryLink }}</LangRouterLink>
        </section>
        <Footer :current-step="1"/>
      </div>
    </template>
  </Transition>
  <Transition name="fade">
    <template v-if="!$page">
      <Loader/>
    </template>
  </Transition>
</template>

<script>
import {setMeta} from "@/helpers/metaHelpers";
import {Banner, ContentCard, Footer, Loader} from "@/components";
import LangRouterLink from "@/components/global/LangRouterLink";

export default {
  components: {Banner, ContentCard, Footer, LangRouterLink, Loader},
  name: "Event",
  beetPage: "terminal-events",
  watch: {
    $page(to) {
      setMeta(to.seo);
    }
  },
  computed: {
    bannerSection() {
      return {
        image: this.$page.header.image,
        subtitle: this.$page.header.subtitle,
        title: this.$page.header.title
      }
    },
    events() {
      return Object.values(this.$beet.events)
          .filter(event => new Date(event.date) > new Date())
          .sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    isFr() {
      return this.$i18n.locale === 'fr'
    },
    libraryLink() {
      return this.isFr ? 'Consulter la bibliothèque' : 'View our library'
    },
    noEvents() {
      return this.isFr ? 'Il n’y a présentement aucun événement à venir chez VITAM. N’hésitez pas à consulter la section Événements passés qui regorge de conférences à visionner!' : 'No events'
    }
  },
  methods: {
    format_date(date) {
      let tmp = new Date(date).toDateString().split(' ')
      return `${tmp[2]} ${tmp[1]} ${tmp[3]}`
    }
  }
};
</script>
